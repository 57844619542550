import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/seo';
import Layout from '../components/layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import ResourcesCardDeck from '../components/CardDeck/variants/ResourcesCardDeck';
import GeneralHead from '../components/generalHead';

const TransformCTAText = styled.div`
  .btn {
    text-transform: none;
  }
`;

const ThankYouTemplate = ({
  data: {
    page: {
      components: [header, hero, CardDeck],
    },
  },
}) => (
  <Layout>
    <Header componentName={header?.component} />
    <TransformCTAText>
      <Hero isWithMenu="true" component={hero} />
    </TransformCTAText>
    <ResourcesCardDeck
      heading="Check out more of our latest resources!"
      cardDeck={CardDeck}
    />
    <Footer />
  </Layout>
);

export const Head = ({
  data: {
    page: { seo, headHtml, indexable, schemaMarkup },
  },
}) => (
  <GeneralHead>
    <SEO
      seo={seo}
      headHtml={headHtml}
      indexable={indexable}
      schemaMarkup={schemaMarkup}
    />
  </GeneralHead>
);

export default ThankYouTemplate;

export const query = graphql`
  query($id: String) {
    page: datoCmsPageTemplateThankYou(id: { eq: $id }) {
      id
      slug
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      indexable
      headHtml
      schemaMarkup {
        id
        schema
      }
      components {
        __typename
        ... on DatoCmsHeaderComponent {
          __typename
          id
          announcement
          internalName
          menu {
            id
            internalName
            menuItem {
              internalName
              label
              link
              menuItemCallToAction {
                url
              }
              childMenuItem {
                internalName
                subhead
                label
                menuItemCallToAction {
                  label
                  url
                }
                childMenuItem {
                  hoverColor {
                    hex
                  }
                  internalName
                  label
                  subhead
                  iconImage {
                    image {
                      gatsbyImageData(
                        imgixParams: { fm: "webp", auto: "compress", q: 35 }
                      )
                      url
                      alt
                    }
                  }
                  fullImage {
                    image {
                      gatsbyImageData(
                        imgixParams: { fm: "webp", auto: "compress", q: 35 }
                      )
                      fluid(
                        imgixParams: { fm: "webp", auto: "compress", q: 35 }
                      ) {
                        ...GatsbyDatoCmsFluid
                      }
                      url
                    }
                  }
                  menuItemCallToAction {
                    url
                  }
                }
              }
            }
          }
          callToAction {
            ...datoCmsCallToAction
          }
        }
        ... on DatoCmsComponentHero {
          ...datoCmsComponentHero
        }
        ... on DatoCmsCardDeckComponent {
          ...datoCmsCardDeckComponent
        }
        ... on DatoCmsFooterComponent {
          __typename
          internalName
          id
          model {
            apiKey
          }
        }
      }
    }
  }
`;
